<template>
  <div class="home">
    <ys-page-list
        ref="table"
        action="check/List"
        :keyID="keyID"
        :tableHeight="tableHeight"
        :params="params"
        :multiBtn="multiBtn"
        showContextMenu
        :multiColumn="true"
        @on-selection-change="checkItem"
        :headerColumns="headerColumns">
      <template slot="search">
        <FormItem label="服务类型" prop="type" v-if="!type">
          <Select v-model="params.type" transfer clearable placeholder="服务类型" style="width: 120px;">
            <Option v-for="(item,index) in checkType" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="服务子类型" prop="subType" v-if="subTypeList.length > 0">
          <Select v-model="params.subType" clearable placeholder="服务子类型" style="width: 120px;">
            <Option v-for="(item,index) in subTypeList" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="状态" prop="statusList" show>
          <Select v-model="params.statusList" transfer multiple class="selectMultiple" :max-tag-count="1" clearable placeholder="状态" style="width: 150px;">
            <Option v-for="(item,index) in baseData['专项任务状态'].filter(item => { return item.value !== 1 && item.value !== 4 && item.value !== 6 && item.value !== 0})" :value="item.value" :key="index">{{ item.name }}
            </Option>
          </Select>
        </FormItem>
        <FormItem label="是否无法服务" prop="canNotServiceStatus" show>
          <Select v-model="params.canNotServiceStatus" placeholder="是否无法服务" transfer clearable>
            <Option v-for="(item,index) in canNotServiceList" :value="item.value" :key="index">{{ item.name }}</Option>
          </Select>
        </FormItem>
        <FormItem label="逾期" prop="overDue">
          <Checkbox v-model="params.overDue" :true-value="1" :false-value="-1" border>是</Checkbox>
        </FormItem>
        <FormItem label="时间筛选" prop="startType" class="formItem">
          <FormItem label="" prop="startType" placeholder="类型" style="width: 100%">
            <Select v-model="params.startType" style="width: 100%">
              <Option v-for="(item,index) in [{name: '创建时间', value: 0},{name: '打卡时间', value: 1},{name: '提交整改单时间', value: 2},{name: '提交报告时间', value: 3}]" :value="item.value" :key="index">{{ item.name }}</Option>
            </Select>
          </FormItem>
          <FormItem label="" prop="daterange" style="width: 100%">
            <ys-date-picker type="daterange" v-model="params.daterange" placeholder="时间周期"></ys-date-picker>
          </FormItem>
        </FormItem>
        <FormItem label="关键词" prop="keyword">
          <Input placeholder="项目名称、任务编号等" style="width: 180px;" v-model="params.keyword"></Input>
        </FormItem>
      </template>
      <template slot="detail">
        <detail :tabNum="tabNum" :mid="detailID"></detail>
      </template>
    </ys-page-list>
    <ys-modal v-model="rectFlag" :width="1000" title="创建整改单">
      <rectificationsAdd @on-ok="getList" :parentID="detailID" taskType="2"></rectificationsAdd>
    </ys-modal>
    <ys-modal :z-index="500" v-model="riskOrProblemShow" :width="1200" title="新增/修改">
      <component :is="riskOrProblemComponent" @on-ok="getList" :parentID="detailID" v-if="riskOrProblemShow===true" :midData="tableSelectItem"></component>
    </ys-modal>
    <ys-modal v-model="addReportFlag" width="95%" :title="tableSelectItem.length===1&&tableSelectItem[0].reportId?'编辑报告':'创建报告'">
      <ReportAdd
          @on-ok="getList"
          :checkId="detailID"
          :checkTypeValue="tableSelectItem.length===1?tableSelectItem[0].type:0"
          :projectId="tableSelectItem.length===1?tableSelectItem[0].projectId:0"
          :serveOrgId="tableSelectItem.length===1?tableSelectItem[0].serveOrgId:0"
          :title="tableSelectItem.length===1&&tableSelectItem[0].reportId?'编辑报告':'创建报告'" taskType="2"></ReportAdd>
    </ys-modal>
    <ys-modal v-model="checkItemFlag" :width="800" title="调整检查项">
      <categoryEdit :mid="detailID" @on-ok="getList"></categoryEdit>
    </ys-modal>
    <ys-modal v-model="modShow" :width="1000" :title="(tableSelectItem.length===0?'新增':'修改')+'任务'">
      <Edit :type="type" :mid="tableSelectItem.length===1?tableSelectItem[0].id:''" @on-ok="taskEdit"></Edit>
    </ys-modal>
  </div>
</template>

<script>
import pageList from '@/mixins/pageList.js';
import {checkConfirm, checkSignIn, checkSelectExpert} from '@/api/check'
import Detail from "@/views/task/detail";
import rectificationsAdd from "@/views/rectifications/Edit";
import ReportAdd from "@/views/report/addReport";
import categoryEdit from "@/views/task/categoryEdit";
import Edit from "@/views/task/edit.vue";

export default {
  name: 'task',
  mixins: [pageList],
  props: {
    type: {type: Number, default: 2},
    keyID: {type: String, default: 'E28DC8518692614E492939F6F3994461'},
    projectId: [Number, String],
  },
  components: {
    ReportAdd,
    categoryEdit,
    rectificationsAdd,
    Detail,
    Edit,
  },
  data() {
    return {
      params: {
        insOrgId: '', // 保险公司
        isTop: false, // 是否不统计服务机构的子机构
        isTopIns: false, // 是否不统计保险公司子机构
        type: '',
        subType: null,
        noArea: true,
        startType: '', // 时间筛选类型，0、1、2、3、4：创建时间、打卡时间、提交整改单时间、提交报告时间、保单有效时间
        daterange: [], // 时间区间
        startAt: '', // 开始结束时间
        endAt: '', // 开始结束时间
        signStartAt: '', // 打卡时间，已统合到开始结束时间内了，根据时间选择类型区分
        signEndAt: '', // 打卡时间，已统合到开始结束时间内了，根据时间选择类型区分
        serveStartAt: '', // 服务时间，已统合到开始结束时间内了，根据时间选择类型区分
        serveEndAt: '', // 服务时间，已统合到开始结束时间内了，根据时间选择类型区分
        reportPublishStartAt: '', // 报告提交时间，已统合到开始结束时间内了，根据时间选择类型区分
        reportPublishEndAt: '', // 报告提交时间，已统合到开始结束时间内了，根据时间选择类型区分
        amendPublishStartAt: '', // 整改单提交时间，已统合到开始结束时间内了，根据时间选择类型区分
        amendPublishEndAt: '', // 整改单提交时间，已统合到开始结束时间内了，根据时间选择类型区分
        tag: '', // 项目关注程度（除政府端其他端用处不大），1：正常关注；2：一般关注；3：重点关注；
        projectId: '', // 项目id
        overDue: -1, // 是否逾期，只要传值除了-1都为只统计逾期数据
        statusList: [], // 任务状态
        status: '', // 任务状态
        policyId: '', // 保单id
        keyword: '', // 项目名称、任务名称、保单编号、投保机构、专家等
        canNotServiceStatus: '',
      },
      checkItemFlag: false,
      addReportFlag: false,
      reportFlag: false,
      riskOrProblemShow: false,
      rectFlag: false,
      tabNum: '',
      canNotServiceList: [
        {
          name: "是",
          value: 1
        },
        {
          name: "否",
          value: 2
        }
      ],
      // headerColumns: []
    }
  },
  watch: {
    'params.daterange'() {
      this.params.startAt = this.params.daterange[0] ? this.params.daterange[0] + ' ' + '00:00:00' : ''
      this.params.endAt = this.params.daterange[1] ? this.params.daterange[1] + ' ' + '23:59:59' : ''
    },
    'params.type'() {
      this.$nextTick(() => {
        this.params.subType = ''
      })
    }
  },
  computed: {
    subTypeList() {
      const type = this.params.type || this.type
      let data = this.checkType.find(item => item.value === type)
      return data?.children || [];
    },
    riskOrProblemComponent() {
      const {type = 2} = this.tableSelectItem[0] || {};
      let component = ''
      if (type === 9) {
        component = () => import("@/views/service/identification/risk/Edit");
      }
      if (type < 3) {
        component = () => import("@/views/problems/Edit");
      }
      return component
    },
    headerColumns() {
      const columns = [
        {type: 'selection', title: '选择框', width: 60, align: 'center'},
        {type: 'index', title: '序号', width: 70, align: 'center'},
        {title: '任务编号', width: 180, key: 'number', align: 'center'},
        {width: 200, title: '任务名称', key: 'iname', align: 'left', renderConfig: {type: 'gotoDetail'}},
        {minWidth: 250, title: '项目名称', key: 'projectName', align: 'left'},
        {width: 120, title: '发布日期', key: 'publishAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 200, title: '投保机构', key: 'contractUnit', align: 'left'},
        {width: 180, title: '服务子类型', key: 'subTypeName', align: 'center'},
        {width: 125, title: '计划服务时间', key: 'serveTime', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '截止时间', key: 'finishAt', align: 'center', renderConfig: {type: 'formatDate'}},
        {width: 120, title: '提交报告单时间', key: 'reportPublishedAt', align: 'center', renderConfig: {type: 'formatDate'}, isShow: this.type === 1 || this.type === 2, display: 'inline-block'},
        {width: 120, title: '提交整改单时间', key: 'amendPublishedAt', align: 'center', renderConfig: {type: 'formatDate'}, isShow: this.type === 1 || this.type === 2, display: 'inline-block'},
        {width: 100, title: '报告状态', key: 'isReported', align: 'center', renderConfig: {type: 'baseData', parentName: '报告状态'}},
        {width: 110, title: '状态', key: 'status', align: 'center', renderConfig: {type: 'baseData', parentName: '专项任务状态'}},
        {width: 200, title: '检查标准', key: 'warehouseName', align: 'center', isShow: this.type === 1 || this.type === 2, display: 'inline-block'},
        {width: 160, title: '签到时间', key: 'signTime', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}, isShow: this.type !== 9, display: 'inline-block'},
        {width: 160, title: '签退时间', key: 'signOutTime', align: 'center', renderConfig: {type: 'formatDate', fmt: 'yyyy-MM-dd hh:mm:ss'}, isShow: this.type !== 9, display: 'inline-block'},
        {width: 150, title: '服务时长', key: 'serviceDuration', align: 'left', isShow: this.type !== 9, display: 'inline-block'},
        {
          width: 130, title: '是否无法服务', key: 'canNotServiceStatus', align: 'center', render: (h, params) => {
            return h('span', params.row.canNotServiceStatus === 0 ? '否' : '是')
          }
        },
      ]
      return columns
    },
    multiBtn() {
      return [
        {
          children: [
            {type: 'detail', title: '查看详情', icon: 'md-eye', disabled: this.tableSelectItem.length !== 1},
          ]
        },
        {
          children: [
            {click: () => this.confirmTask(), title: '确认', icon: 'md-checkbox', disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 2},
          ]
        },
        {
          children: [
            {
              click: () => this.checkItemFlag = true,
              title: '调整检查项',
              icon: 'ios-apps',
              show: this.type < 3,
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 5
            },
            {
              click: () => this.riskOrProblemShow = true,
              title: '新增隐患',
              icon: 'md-add',
              show: this.type < 3,
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 5 || this.tableSelectItem[0].amendPublishedAt !== ''
            },
            {
              click: () => this.riskOrProblemShow = true,
              title: '新增风险',
              icon: 'md-add',
              show: this.type === 9,
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status !== 5
            },
            {
              click: () => this.rectFlag = true,
              title: '创建整改单',
              icon: 'md-clipboard',
              show: this.type <= 2,
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].amendId > 0 || this.tableSelectItem[0].status < 5
            },
            {
              click: () => this.addReportFlag = true,
              title: '编辑报告',
              icon: 'ios-list-box-outline',
              disabled: this.tableSelectItem.length !== 1 || this.tableSelectItem[0].status < 5 || this.tableSelectItem[0].status === 99 || this.tableSelectItem[0].isReported === 1
            },
          ]
        },
      ]
    },
  },
  mounted() {
    const {query} = this.$router.currentRoute;
    this.params.overDue = query.overDue ? Number(query.overDue) : '';
    this.params.statusList = query.statusList ? JSON.parse(query.statusList) : [];
    this.params.type = this.type;
    this.params.projectId = this.projectId;
    this.params.orgId = this.userInfo.org_id;
    this.getList();
  },
  methods: {
    dateChange(date) {
      this.params.ServeTimeStartAt = date[0]
      this.params.ServeTimeEndAt = date[1]
    },
    showCheckObj() {
      this.tabNum = '2'
      this.detailFlag = true
    },
    confirmTask() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要开始处理该任务?' + (this.tableSelectItem[0].type < 3 ? '该任务使用的检查标准为【' + this.tableSelectItem[0].warehouseName + '】，如不对请点击调整前往修改。' : ''),
        okText: '确定',
        cancelText: this.tableSelectItem[0].type < 3 ? '调整' : '取消',
        onOk: () => {
          checkConfirm({id: this.tableSelectItem[0].id}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '操作成功！'
              });
              this.getList();
            }
          });
        },
        onCancel: () => {
          if (this.tableSelectItem[0].type < 3) {
            this.checkItemFlag = true;
          }
        },
      });
    },
    sign() {
      this.$Modal.confirm({
        title: '提示',
        content: '确认要打卡么?',
        okText: '确定',
        cancelText: '取消',
        onOk: () => {
          checkSignIn({id: this.tableSelectItem[0].id, lng: '', lat: '',}).then((res) => {
            if (res.code === 200) {
              this.$Notice.success({
                title: '操作成功！'
              });
              this.getList();
            }
          });
        }
      });
    },
    async taskEdit(data, nextType) {
      if (nextType === 'saveAndRelease') {
        checkSelectExpert({id: data.id, noExpert: true, serveTime: data.serveTime}).then(res => {
          if (res.code === 200) {
            this.$Notice.success({
              title: '发布成功！'
            });
            this.getList()
          }
        })
      }
    },
  },
}
</script>
